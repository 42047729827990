import React, { ReactNode } from 'react'
//
import { StyledButton } from './Styled'

type Props = {
  theme?: string
  disabled?: boolean
  children: ReactNode
  type?: string
  fullWidth?: boolean
  hollow?: boolean
  className?: string
  onClick?: Function
  title?: string
}

const Button = ({
  theme = 'black',
  type,
  disabled,
  fullWidth,
  hollow,
  children,
  className,
  onClick,
  ...rest
}: Props) => (
  <StyledButton
    disabled={!!disabled}
    buttonTheme={theme}
    type={type}
    fullWidth={fullWidth}
    hollow={hollow}
    className={className}
    onClick={onClick}
    {...rest}
  >
    {children}
  </StyledButton>
)

export default Button
