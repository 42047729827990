import styled from 'styled-components'
//
import { Media } from '~utils'

export const noOfColumns = 12
export const maxWidth = 1920
export const gutters = 0.75

type NumberOrNull = number | null
type BooleanOrNull = boolean | null

export const getColumnWidth = (cols: number) => `${(cols / noOfColumns) * 100}`

interface GridProps {
  justify?: string
}

export const Grid = styled.div<GridProps>`
  max-width: ${maxWidth}px;
  justify-content: ${props => props.justify || 'flex-start'};
  display: flex;
  padding: 0 ${gutters}rem;
  margin: 0 auto;
  width: 100%;
  flex-flow: row wrap;
`

interface CellProps {
  cols?: Array<NumberOrNull>
  offset?: Array<NumberOrNull>
  order?: Array<NumberOrNull>
  show?: Array<BooleanOrNull>
  shrink?: boolean
}

export const Cell = styled.div<CellProps>`
  flex: ${props => (props.cols || props.shrink ? '0 0 auto' : '1')};
  padding: 0 ${gutters}rem;

  ${props =>
    Media({
      ...(props.cols && {
        width: props.cols.map(breakpointCols => (breakpointCols !== null ? `${getColumnWidth(breakpointCols)}%` : null))
      }),
      ...(props.offset && {
        marginLeft: props.offset.map(breakpointOffset =>
          breakpointOffset !== null ? `${getColumnWidth(breakpointOffset)}%` : null
        )
      }),
      ...(props.order && {
        order: props.order
      }),
      ...(props.show && {
        display: props.show.map(breakpointShow => {
          if (breakpointShow === null) {
            return null
          }
          if (breakpointShow) {
            return 'block'
          }
          return 'none'
        })
      })
    })}
`
