import styled from 'styled-components'
//
import Media from '~utils/media'

interface StyledButtonProps {
  buttonTheme: string
  disabled: boolean
  fullWidth?: boolean
  hollow?: boolean
}

// eslint-disable-next-line import/prefer-default-export
export const StyledButton = styled.button<StyledButtonProps>`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  height: 40px;
  text-transform: uppercase;
  border-radius: 10px;
  letter-spacing: 2px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  ${props =>
    Media({
      width: [props.fullWidth ? '100%' : '160px', null, '160px']
    })}

  > * {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  ${props =>
    props.buttonTheme === 'black' &&
    !props.disabled &&
    !props.hollow &&
    `
    border-color: ${props.theme.colors.black};
    background-color: ${props.theme.colors.black};
    color: ${props.theme.colors.white};

    * {
      color: ${props.theme.colors.white};
    }

    &:hover {
      background-color: transparent;
      color: ${props.theme.colors.black};

      * {
        color: ${props.theme.colors.black};
      }
    }
  `}

  ${props =>
    props.buttonTheme === 'black' &&
    !props.disabled &&
    props.hollow &&
    `
    border-color: ${props.theme.colors.black};
    background-color: transparent;
    color: ${props.theme.colors.black};

    * {
      color: ${props.theme.colors.black};
    }

    &:hover {
      background-color: ${props.theme.colors.black};
      color: ${props.theme.colors.white};

      * {
        color: ${props.theme.colors.white};
      }
    }
  `}

  ${props =>
    props.buttonTheme === 'white' &&
    !props.disabled &&
    !props.hollow &&
    `
    border-color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.black};

    * {
      color: ${props.theme.colors.black};
    }

    &:hover {
      background-color: transparent;
      color: ${props.theme.colors.white};

      * {
        color: ${props.theme.colors.white};
      }
    }
  `}

  ${props =>
    props.buttonTheme === 'white' &&
    !props.disabled &&
    props.hollow &&
    `
    border-color: ${props.theme.colors.white};
    background-color: transparent;
    color: ${props.theme.colors.white};

    * {
      color: ${props.theme.colors.white};
    }

    &:hover {
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.black};

      * {
        color: ${props.theme.colors.black};
      }
    }
  `}

  ${props =>
    props.disabled &&
    `
    border-color: ${props.theme.colors.lightGrey};
    background-color: ${props.theme.colors.lightGrey};
    color: ${props.theme.colors.lightestGrey};

    * {
      color: ${props.theme.colors.lightestGrey};
    }
  `}
`
