import { LocaleType } from './LocaleType'

const locales: Record<string, LocaleType> = {
  'en-US': {
    id: 'en-US',
    name: 'English',
    iso: 'en',
    path: ''
  }
}

export default locales
