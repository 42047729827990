import * as React from 'react'
import { Helmet } from 'react-helmet'
//
import { MetaDataProps } from '~utils/seo'

const Metatags = ({
  siteName,
  title,
  description,
  canonical,
  nofollow,
  noindex,
  image,
  pageType,
  pageLocale = 'en-US'
}: MetaDataProps) => {
  const metaLinks = []
  const metaTags = []

  if (canonical) {
    metaLinks.push({
      rel: 'canonical',
      href: canonical
    })
  }

  if (image) {
    metaTags.push({
      name: 'og:image',
      content: image
    })

    metaTags.push({
      name: 'twitter:image',
      content: image
    })
  }

  // Handle nofollow and noindex
  if (nofollow && noindex) {
    metaTags.push({
      name: 'robots',
      content: 'noindex, nofollow'
    })
  } else if (nofollow) {
    metaTags.push({
      name: 'robots',
      content: 'nofollow'
    })
  } else if (noindex) {
    metaTags.push({
      name: 'robots',
      content: 'noindex'
    })
  }

  metaTags.push({
    property: 'og:title',
    content: title
  })

  metaTags.push({
    property: 'twitter:title',
    content: title
  })

  metaTags.push({
    name: 'twitter:card',
    content: 'summary'
  })

  metaTags.push({
    property: 'og:type',
    content: 'website'
  })

  metaTags.push({
    name: 'twitter:card',
    content: 'summary_large_image'
  })

  metaTags.push({
    name: 'og:site_name',
    content: siteName
  })

  if (description) {
    metaTags.push({
      name: 'description',
      content: description
    })
    metaTags.push({
      property: 'og:description',
      content: description
    })
    metaTags.push({
      name: 'twitter:description',
      content: description
    })
  }

  return (
    <Helmet>
      <title>{`${siteName} | ${title}`}</title>
      {metaTags.map((tag: any) => (
        <meta key={tag.name || tag.property} {...tag} />
      ))}
      {metaLinks.map((tag: any) => (
        <link key={tag.rel} {...tag} />
      ))}
      <meta name="theme-color" content="#1D1D1D" />
      <meta name="page-title" data-type="string" content={title} />
      <meta name="page-lang" data-type="string" content={pageLocale} />
      <meta name="page-type" data-type="string" content={pageType} />
    </Helmet>
  )
}

export default Metatags
