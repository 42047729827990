// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-barista-tsx": () => import("./../../../src/templates/Barista.tsx" /* webpackChunkName: "component---src-templates-barista-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/BasicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/Recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */),
  "component---src-templates-technique-tsx": () => import("./../../../src/templates/Technique.tsx" /* webpackChunkName: "component---src-templates-technique-tsx" */)
}

