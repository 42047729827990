import React from 'react'
//
import { SiteDataContext } from '~layout/wrapper'
import {
  ContentfulBasicPage,
  ContentfulBarista,
  ContentfulRecipe,
  ContentfulTechnique,
  ContentfulEvent
} from '~generated/gatsby.types'
import { getSourceImage } from '~utils/image'
import { IntlContext } from '~i18n'

const pageTypes: { [key: string]: string } = {
  ContentfulBasicPage: 'basic-page',
  ContentfulRecipe: 'recipe',
  ContentfulTechnique: 'technique',
  ContentfulBarista: 'barista',
  Page404: 'page-404'
}

type Page404 = {
  __typename: string
  title?: string
  metaTags: any
  image?: any
}

export type MetaDataProps = {
  siteName: string
  title: string
  description?: string
  canonical?: string
  noindex?: boolean
  nofollow?: boolean
  image?: string
  pageLocale?: string
  pageType: string
}

export const buildPageMetaTags = (
  pageData:
    | ContentfulEvent
    | ContentfulBasicPage
    | ContentfulBarista
    | ContentfulRecipe
    | ContentfulTechnique
    | Page404,
  site: any
) => {
  const settings: any = React.useContext(SiteDataContext)
  const intl: any = React.useContext(IntlContext)
  const metaTags = pageData?.metaTags
  const pageImage = pageData?.image
  // eslint-disable-next-line no-underscore-dangle
  const pageType = pageTypes[pageData?.__typename || 'Page404']
  const { siteName, siteUrl } = site.siteMetadata
  const pageLocale = intl.id

  const title = metaTags?.title || pageData?.title || settings.siteTitle || ''
  const description = metaTags?.description || settings?.siteDescription.siteDescription || ''

  const sourceImage = getSourceImage(pageImage, 'fixed')
  let image = ''

  if (sourceImage && sourceImage.src) {
    image = sourceImage.src
  } else {
    const siteImage = getSourceImage(settings.siteImage, 'fixed')
    if (siteImage && siteImage.src) {
      image = siteImage.src
    }
  }

  let canonical = ''

  if (metaTags && metaTags.canonical) {
    if (metaTags.canonical?.startsWith('http')) {
      canonical = metaTags.canonical
    } else {
      canonical = `${siteUrl}/${metaTags.canonical}`.replace('//', '/')
    }
  }

  const noindex = metaTags?.noindex || false
  const nofollow = metaTags?.nofollow || false

  const pageMetaTags: MetaDataProps = {
    siteName,
    title,
    description,
    image,
    canonical,
    noindex,
    nofollow,
    pageLocale,
    pageType
  }

  return pageMetaTags
}
