import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
//
import '../../fonts/geomanist/font-faces.css'
import '../../fonts/caveat/font-faces.css'
import Theme from '~theme'

export default createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    height: auto;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    background: ${Theme.colors.background};
    color: ${Theme.colors.text};

    &.overflow-hidden {
      overflow: hidden;
    }
  }
`
