import * as React from 'react'
import { FormattedMessage, IntlContext } from 'react-intl'
//
import { Cell } from '~components/base-components/grid'
import { buildNavigationMenu, NavItemType, NavLink } from '~utils/link'
import { SiteDataContext } from '~layout/wrapper'
import {
  StyledOuterContainer,
  StyledInnerContainer,
  StyledGridTop,
  StyledGridDivider,
  StyledGridBottom,
  StyledCell,
  StyledCopyright
} from './Styled'

const Footer = () => {
  const settings: any = React.useContext(SiteDataContext)
  const intl: any = React.useContext(IntlContext)
  const { footerLeftNavigationTitle, footerRightNavigationTitle } = settings
  const leftNavigation = buildNavigationMenu('footerLeftNavigation')
  const rightNavigation = buildNavigationMenu('footerRightNavigation')
  const legalNavigation = buildNavigationMenu('footerLegalNavigation')

  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        <StyledGridTop>
          <StyledCell cols={[12, 12, 6, 6]}>
            <h3>{footerLeftNavigationTitle}</h3>
            <ul>
              {leftNavigation.map((item: NavItemType) => (
                <li key={`footer-left-${item.id}`}>
                  <NavLink
                    to={item.to}
                    ariaLabel={item.accessibleText || intl.formatMessage({ id: 'a11y.menu' }, { title: item.title })}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </StyledCell>
          <StyledCell cols={[12, 12, 6, 6]}>
            <h3>{footerRightNavigationTitle}</h3>
            <ul>
              {rightNavigation.map((item: NavItemType) => (
                <li key={`footer-right-${item.id}`}>
                  <NavLink
                    to={item.to}
                    ariaLabel={item.accessibleText || intl.formatMessage({ id: 'a11y.menu' }, { title: item.title })}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </StyledCell>
        </StyledGridTop>

        <StyledGridDivider>
          <Cell />
        </StyledGridDivider>

        <StyledGridBottom>
          <Cell cols={[12, 12, 12, 9]}>
            <ul>
              {legalNavigation.map((item: NavItemType) => (
                <li key={`footer-right-${item.id}`}>
                  <NavLink
                    to={item.to}
                    ariaLabel={item?.accessibleText || intl.formatMessage({ id: 'a11y.menu' }, { title: item.title })}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Cell>

          <Cell cols={[12, 12, 12, 3]}>
            <StyledCopyright>
              <FormattedMessage id="footer.copyright" values={{ year: new Date().getFullYear() }} />
            </StyledCopyright>
          </Cell>
        </StyledGridBottom>
      </StyledInnerContainer>
    </StyledOuterContainer>
  )
}

export default Footer
