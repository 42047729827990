import facepaint from 'facepaint'
//
import { smallBreakpoint, mediumBreakpoint, largeBreakpoint, xlargeBreakpoint } from './breakpoints'

export default facepaint([
  `@media(min-width: ${smallBreakpoint}px)`,
  `@media(min-width: ${mediumBreakpoint}px)`,
  `@media(min-width: ${largeBreakpoint}px)`,
  `@media(min-width: ${xlargeBreakpoint}px)`
])
