import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { getCurrentLangKey } from 'ptz-i18n'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie'
import { locales, LocaleType, IntlContextProvider, getMessages } from '~i18n'
//
import { LocationContextProvider } from './Location'
import { GlobalStyle } from '~components/styled'
import theme from '~theme'
import { ContentfulSettings } from '~generated/gatsby.types'

type dataProps = {
  contentfulSettings: ContentfulSettings
}

type Props = {
  children: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any
  data: dataProps
  enabledLocales: Array<string>
  defaultLocaleId: string
}

export const SiteDataContext = React.createContext({} as any)
export const EnabledLocalesContext = React.createContext({} as any)

const PageWrapper = ({ children, location, data, enabledLocales, defaultLocaleId = 'en-US' }: Props) => {
  if (location.pathname === '/offline-plugin-app-shell-fallback/') {
    return null
  }

  const filteredLocales: Record<string, LocaleType> = {}
  const availableLangs: Array<string> = []
  const { contentfulSettings } = data
  let defaultLocale: LocaleType = locales.nl

  Object.keys(locales)
    .filter(id => enabledLocales.includes(id))
    .forEach(id => {
      filteredLocales[id] = locales[id]
      availableLangs.push(locales[id].id)
      if (defaultLocaleId === id) {
        defaultLocale = locales[id]
      }
    })

  const currentLangKey = getCurrentLangKey(availableLangs, defaultLocaleId, location.pathname)

  const currentLocale =
    Object.keys(filteredLocales)
      .map((id: string) => filteredLocales[id])
      .find((locale: LocaleType) => locale.id === currentLangKey) ||
    defaultLocale ||
    locales['en-US']

  const i18nMessages: Record<string, any> = getMessages(currentLocale.id)

  return (
    <IntlProvider locale={currentLocale.id} key={currentLocale.id} messages={i18nMessages}>
      <EnabledLocalesContext.Provider value={filteredLocales}>
        <IntlContextProvider value={currentLocale}>
          <LocationContextProvider value={location}>
            <SiteDataContext.Provider value={contentfulSettings}>
              <ThemeProvider theme={theme}>
                <CookiesProvider>
                  <Helmet
                    htmlAttributes={{
                      lang: currentLocale.id
                    }}
                  />
                  <GlobalStyle />
                  {children}
                </CookiesProvider>
              </ThemeProvider>
            </SiteDataContext.Provider>
          </LocationContextProvider>
        </IntlContextProvider>
      </EnabledLocalesContext.Provider>
    </IntlProvider>
  )
}

export default PageWrapper
