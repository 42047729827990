import React from 'react'
import { IntlContext as ReactIntlContext } from 'react-intl'
//
import { IntlContext } from '~i18n'

// Implement here common utilities

// Support for multiline text fields
export const multilineTextField = (title: string | null | undefined, span = false) => {
  if (!title) {
    return ''
  }

  const titlePieces = title.trim().split(/(?:\r\n|\r|\n|\\n)/g)
  const total = titlePieces.length
  const multiline: any = []

  titlePieces.forEach((value: string, idx: number) => {
    if (idx < total - 1) {
      multiline.push(
        <React.Fragment key={`text-${Math.random()}`}>
          {span ? <span>{value}</span> : value}
          {value ? <br /> : null}
        </React.Fragment>
      )
    } else {
      multiline.push(
        <React.Fragment key={`text-${Math.random()}`}>{span ? <span>{value}</span> : value}</React.Fragment>
      )
    }
  })

  return multiline
}

// Prefix slugs with locale path
export const getIntlSlug = (slug = '') => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const intl: any = React.useContext(IntlContext)

  if (!slug.startsWith(`/${intl.path}`) || !slug.startsWith(`${intl.path}/`)) {
    return `/${intl.path}/${slug}`.replace('//', '/')
  }

  return slug
}

// Get URL parameter helper
export const getUrlParameter = (url: string, query: string) => {
  // eslint-disable-next-line
  const name = query.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  // eslint-disable-next-line
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const queryParams = (params: any) =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

export const getRandomIndex = (arr?: Array<any> | null) => {
  if (!arr || !arr.length) {
    return 0
  }

  const min = Math.ceil(0)
  const max = Math.floor(arr.length - 1)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

const prefixNames: any = {
  ContentfulBarista: 'barista.prefix',
  ContentfulRecipe: 'recipe.prefix',
  ContentfulTechnique: 'technique.prefix',
  barista: 'barista.prefix',
  recipe: 'recipe.prefix',
  technique: 'technique.prefix'
}

export const getEntryPrefix = (typename?: string) => {
  if (!typename) {
    return null
  }
  const intl: any = React.useContext(ReactIntlContext)
  const prefix = prefixNames[typename] && intl.formatMessage({ id: prefixNames[typename] })
  return prefix
}
