import React, { ReactNode } from 'react'
import { Document, INLINES, Inline, Block, BLOCKS } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import {
  Options,
  RenderNode,
  documentToReactComponents as contentFulDocumentToReactComponents,
  RenderMark
} from '@contentful/rich-text-react-renderer'
//
import { buildSlug } from '~utils/link'
import { StyledText } from './Styled'
import { IntlContext } from '~i18n'
import { ImageFixed, ImageFluid } from '~utils/image'

export type RichTextProps = {
  /** Contentful rich text data object */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

const inlineHyperlink = (hyperlink: Inline): ReactNode => {
  const { uri } = hyperlink.data
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { value } = hyperlink?.content[0]

  if (!uri || !value) {
    return null
  }

  return (
    <a href={uri} target="_blank" rel="noreferrer nofollow">
      {value}
    </a>
  )
}

const entryHyperlink = (hyperlink: Inline): ReactNode => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { value } = hyperlink?.content[0]
  const { data } = hyperlink

  const slug = data?.target?.fields?.slug['en-US']
  const type = data?.target?.sys?.contentType?.sys?.id
  const id = data?.target?.sys?.contentful_id

  if (!value || !slug || !type || !id) {
    return null
  }

  const content = {
    __typename: type,
    id,
    slug
  }

  return <Link to={buildSlug(content)}>{value}</Link>
}

const getAsset = (locale: string, fields: any) => {
  const { localAsset, bynderAsset } = fields

  const field =
    (localAsset && ((localAsset[locale] && localAsset[locale]?.fields) || localAsset['en-US']?.fields)) ||
    (bynderAsset && ((bynderAsset[locale] && bynderAsset[locale]?.fields) || bynderAsset['en-US']?.fields)) ||
    null
  if (!field) {
    return null
  }

  const { title, file } = field

  return { title: title[locale] || title['en-US'], file: file[locale] || file['en-US'] }
}

const inlineEntryBlock = (node: Block) => {
  const intl: any = React.useContext(IntlContext)

  const { sys, fields } = node.data.target
  const id = sys?.contentType?.sys?.id

  if (!id || !sys || !fields) {
    return null
  }

  if (id === 'image') {
    const asset = getAsset(intl.id, fields)

    if (!asset) {
      return null
    }

    const src = `${asset.file.url}?w=1920&q=80`
    const srcSet = `${asset.file.url}?w=480&h=320&q=80 480w,${asset.file.url}?w=960&h=640&q=80 960w,${asset.file.url}?w=1920&h=800&q=80 1920w`

    const image = {
      fluid: {
        aspectRatio: 1.5,
        sizes: '(max-width: 1920px) 100vw, 1920px',
        src,
        srcSet
      }
    }

    return (
      <div className="image-container">
        <ImageFluid image={image} />
      </div>
    )
  }

  if (id === 'imageCentered') {
    const asset = getAsset(intl.id, fields)

    const height = asset?.file.details.image.height
    const width = asset?.file.details.image.width

    if (!asset) {
      return null
    }

    const src = `${asset.file.url}?w=${width}&h=${height}&q=80`

    return (
      <div className="image-container-centered">
        <ImageFixed image={{ fixed: { src, width, height } }} objectFit="cover" />
      </div>
    )
  }

  if (id === 'podcast') {
    const url = fields.url[intl.id]

    return (
      <div className="podcast-container">
        <iframe
          src={`${url}`}
          width="100%"
          height="180"
          frameBorder="0"
          scrolling="no"
          seamless="true"
          style={{ width: '100%', height: '180px' }}
        />
      </div>
    )
  }

  return null
}

const inlineText = (text: string): string => {
  return /^\s+$/.test(text) ? '' : text
}

const defaultNodeRenderers: RenderNode = {
  [INLINES.HYPERLINK]: node => inlineHyperlink(node as Inline),
  [INLINES.ENTRY_HYPERLINK]: node => entryHyperlink(node as Inline),
  [BLOCKS.EMBEDDED_ENTRY]: node => inlineEntryBlock(node as Block)
}

const defaultMarkRenderers: RenderMark = {}

export function documentToReactComponents(richTextDocument: Document, options: Partial<Options> = {}): ReactNode {
  if (!richTextDocument) {
    return null
  }

  return contentFulDocumentToReactComponents(richTextDocument, {
    renderNode: {
      ...defaultNodeRenderers,
      ...options.renderNode
    },
    renderMark: {
      ...defaultMarkRenderers,
      ...options.renderMark
    },
    renderText: text =>
      inlineText(text)
        .split('\n')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((children: any, textSegment: any, index: any) => {
          return [...children, index > 0 && <br />, textSegment]
        }, [])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((child: any) => {
          if (child.type === 'br') {
            return React.cloneElement(child, { key: `${Math.random()}` })
          }

          return child
        })
  })
}

const RichText = ({ data }: RichTextProps) => <StyledText>{documentToReactComponents(data)}</StyledText>

export default RichText
