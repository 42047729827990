import React from 'react'
//
import {
  StyledCookieSetting,
  StyledInputContainer,
  StyledInput,
  StyledLabel,
  StyledLabelText,
  StyledFakeInput
} from './Styled'

const noop = () => {}

type SettingProps = {
  id: string
  checked?: boolean
  disabled?: boolean
  onChange?: Function
  value?: string
  shortDescription?: any
}

const Setting = ({ id, value, shortDescription, checked, disabled, onChange = noop }: SettingProps) => (
  <StyledCookieSetting>
    <StyledLabel htmlFor={`${id}-cookies`}>
      <StyledInputContainer>
        <StyledInput
          type="checkbox"
          checked={checked}
          disabled={disabled}
          id={`${id}-cookies`}
          onChange={e => onChange(e.target.checked)}
        />
        <StyledFakeInput />
      </StyledInputContainer>
      <StyledLabelText>
        {value}: {shortDescription?.shortDescription}
      </StyledLabelText>
    </StyledLabel>
  </StyledCookieSetting>
)

export default Setting
