import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
//
import { getUrlParameter } from './common'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any
  alt?: string
  loading?: 'lazy' | 'eager' | 'auto'
  fadeIn?: boolean
  className?: string
  objectFit?: 'contain' | 'cover'
}

export const ImageFluid = ({ image, alt = '', loading = 'lazy', fadeIn = true, className, ...rest }: Props) => {
  if (!image || !image.fluid) {
    return null
  }

  return <Img fluid={image.fluid} alt={alt} loading={loading} fadeIn={fadeIn} className={className} {...rest} />
}

export const ImageMobileFluid = ({ image, alt = '', loading = 'lazy', fadeIn = true, className }: Props) => {
  if (!image || (!image.mobileFluid && !image.fluid)) {
    return null
  }

  return (
    <Img fluid={image.mobileFluid || image.fluid} alt={alt} loading={loading} fadeIn={fadeIn} className={className} />
  )
}

export const ImageFixed = ({
  image,
  alt = '',
  loading = 'lazy',
  fadeIn = true,
  className,
  objectFit = 'contain'
}: Props) => {
  const { fixed } = image

  if (!fixed || !fixed.src) {
    return null
  }

  if (!fixed.width) {
    fixed.width = parseInt(getUrlParameter(fixed.src, 'w'), 10)
  }
  if (!fixed.height) {
    fixed.height = parseInt(getUrlParameter(fixed.src, 'h'), 10)
  }
  if (!fixed.srcSet) {
    fixed.srcSet = `${image.fixed.src} 1x`
  }

  return <Img fixed={fixed} alt={alt} objectFit={objectFit} loading={loading} fadeIn={fadeIn} className={className} />
}

export const getSourceImage = (image: any, format?: string) => {
  if (!image) {
    return null
  }

  const { localAsset, local, bynder, bynderAsset } = image

  if (localAsset && format && localAsset[format]) {
    return localAsset[format]
  }
  if (localAsset && !format) {
    return localAsset
  }

  if (local && format && local[format]) {
    return local[format]
  }
  if (local && !format) {
    return local
  }

  if (bynderAsset && format && bynderAsset[format]) {
    return bynderAsset[format]
  }
  if (bynderAsset && !format) {
    return bynderAsset
  }

  if (bynder && format && bynder[format]) {
    return bynder[format]
  }
  if (bynder && !format) {
    return bynder
  }

  return null
}
