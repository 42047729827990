/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension, no-undef */
import 'babel-polyfill'
import React from 'react'
import TagManager from 'react-gtm-module'
//
import { PageWrapper } from '~layout'
import { getCookieConsent, cookieConsentGiven } from '~utils'

const locale = process.env.DEFAULT_LOCALE
const locales = process.env.ENABLED_LOCALES.split(',').map(loc => loc.trim())

/**
 * Implements Gatsby wrapPageElement(params).
 */
export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props} defaultLocaleId={locale} enabledLocales={locales}>
    {element}
  </PageWrapper>
)

export const onClientEntry = () => {
  if (process.env.GTM_ID && (process.env.NODE_ENV === 'production' || process.env.GTM_DEV_ENABLED === 'true')) {
    TagManager.initialize({ gtmId: process.env.GTM_ID })
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (
    cookieConsentGiven() &&
    (process.env.NODE_ENV === 'production' || process.env.GTM_DEV_ENABLED === 'true') &&
    window.dataLayer
  ) {
    const cookieConsent = getCookieConsent()

    setTimeout(() => {
      window.dataLayer.push({
        event: `gatsby-route-change`,
        newLocation: location.pathname,
        prevLocation: prevLocation ? prevLocation.pathname : null,
        ...cookieConsent
      })
    }, 50)
  }
}
