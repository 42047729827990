import React from 'react'
//
import { Header, Footer, Cookies } from '~components'
import { StyledRootContainer, StyledMain } from './Styled'
import { Metatags } from '~components/seo'
import { MetaDataProps } from '~utils/seo'
import { ContentfulSettings } from '~generated/gatsby.types'

type PageProps = {
  children: React.ReactNode | React.ReactNode[]
  metaTags: MetaDataProps
  contentfulSettings: ContentfulSettings
}

const MainLayout = ({ children, metaTags, contentfulSettings }: PageProps) => {
  return (
    <StyledRootContainer>
      <Metatags {...metaTags} />
      <Cookies contentfulSettings={contentfulSettings} />
      <Header />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </StyledRootContainer>
  )
}

export default MainLayout
