/* eslint-disable import/prefer-default-export */
import { useEffect, useState, useCallback, useRef } from 'react'
import { breakpoints } from './breakpoints'

const getMedia = (index: number, formattedBreakpoints: number[]) => {
  const minWidth = `(min-width: ${formattedBreakpoints[index]}px)`
  const maxWidth =
    index < formattedBreakpoints.length - 1 ? `(max-width: ${formattedBreakpoints[index + 1] - 1}px)` : ''

  if (maxWidth) {
    return `${minWidth} and ${maxWidth}`
  }

  return minWidth
}

export const useCurrentBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(breakpoints[breakpoints.length - 1])
  const formattedBreakpoints = [0, ...breakpoints]

  useEffect(() => {
    const mediaQueryLists = formattedBreakpoints.map((breakpoint, index) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handler = (e: any) => {
        if (e.matches) {
          setCurrentBreakpoint(breakpoint)
        }
      }

      const media = getMedia(index, formattedBreakpoints)
      const mql = window.matchMedia(media)

      mql.addListener(handler)

      if (mql.matches) {
        setCurrentBreakpoint(breakpoint)
      }

      return {
        mql,
        handler
      }
    })

    return () => {
      mediaQueryLists.forEach(({ mql, handler }) => {
        mql.removeListener(handler)
      })
    }
  }, [])

  return currentBreakpoint
}

export const useResize = () => {
  const isBrowser = typeof window !== 'undefined'

  const [dimensions, setDimensions] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0
  })

  const handler = useCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [setDimensions])

  useEffect(() => {
    window.addEventListener('resize', handler, false)
    return () => {
      window.removeEventListener('resize', handler, false)
    }
  }, [handler])

  return dimensions
}

type IntersectProps = {
  root?: any
  rootMargin: string
  threshold?: number
}

export const useIntersect = ({ rootMargin, root = null, threshold = 0 }: IntersectProps) => {
  const [entry, updateEntry] = useState({} as any)
  const [node, setNode] = useState(null as null | Element)

  if (typeof window !== 'undefined') {
    const observer = useRef(
      new window.IntersectionObserver(([_entry]) => updateEntry(_entry), {
        root,
        rootMargin,
        threshold
      })
    )

    useEffect(() => {
      const { current: currentObserver } = observer
      currentObserver.disconnect()

      if (node) currentObserver.observe(node)

      return () => currentObserver.disconnect()
    }, [node])
  }

  return [setNode, entry]
}
