import styled from 'styled-components'
//
import { Media } from '~utils'
import { Grid, Cell } from '../base-components/grid'

export const StyledOuterContainer = styled.div`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.oatmealBrown};

  a {
    color: ${props => props.theme.colors.oatmealBrown};
    text-decoration: none;
    letter-spacing: 0.5px;
    &:hover {
      color: #9e9e9e;
    }
  }

  ul {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  hr {
    height: 1px;
    background-image: linear-gradient(
      to bottom,
      ${props => props.theme.colors.oatmealBrown} 0%,
      ${props => props.theme.colors.oatmealBrown} 51%,
      transparent 51%
    );
    background-size: 100% 1px;
  }

  h3 {
    font-weight: 400;
    letter-spacing: 1px;
    color: ${props => props.theme.colors.oatmealBrown};
    margin: 0px;
    padding: 0px;
  }
`

export const StyledInnerContainer = styled.div`
  ${Media({
    paddingTop: ['40px', null, '54px'],
    paddingBottom: ['54px', null, '44px'],
    paddingLeft: [null, null, '32px'],
    paddingRight: [null, null, '54px']
  })}
`

export const StyledGridTop = styled(Grid)`
  ${Media({
    marginBottom: [null, null, '80px']
  })}

  h3 {
    width: 100%;
    font-size: 28px;

    ${Media({
      paddingBottom: ['24px', null, '30px']
    })}

    &:after {
      content: '';
      width: 100%;
      display: block;
      margin-top: 30px;
      border-bottom: 1px solid ${props => props.theme.colors.white};
      ${Media({
        opacity: [0.45, null, 1]
      })}
    }
  }

  ul {
    li {
      font-size: 16px;
      ${Media({
        paddingTop: ['12px', null, '8px'],
        paddingBottom: ['12px', null, '8px']
      })}
    }
  }
`

export const StyledCell = styled(Cell)`
  ${Media({
    marginBottom: ['20px', null, null]
  })}
`

export const StyledGridDivider = styled(Grid)`
  > div {
    &:after {
      content: '';
      display: block;
      opacity: 0.45;
      border-bottom: 1px solid ${props => props.theme.colors.white};
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`

export const StyledGridBottom = styled(Grid)`
  ul {
    ${Media({
      marginTop: ['15px', null, '10px']
    })};

    li {
      white-space: nowrap;

      ${Media({
        display: ['block', null, 'inline'],
        paddingTop: ['12px', null, null],
        paddingBottom: ['12px', null, null]
      })};

      a {
        color: ${props => props.theme.colors.lightGrey};
        font-size: 14px;
        &:hover {
          color: ${props => props.theme.colors.oatmealBrown};
        }
        ${Media({
          letterSpacing: ['0.44px', null, '0.5px']
        })};
      }

      &:not(:last-child):after {
        ${Media({
          paddingLeft: [null, null, '5px'],
          paddingRight: [null, null, '5px'],
          content: [null, null, "'\\•'"]
        })};
      }
    }
  }
`

export const StyledCopyright = styled.div`
  color: ${props => props.theme.colors.lightGrey};
  width: 100%;

  ${Media({
    fontSize: ['8px', null, '14px'],
    marginTop: ['25px', '10px', '10px'],
    textAlign: ['left', 'left', 'right'],
    letterSpacing: ['0.31px', null, '0.44px']
  })}

  @media (max-width: 1024px) {
    text-align: left;
  }
`
