import styled from 'styled-components'

interface StyledContainerProps {
  position: string
}

// eslint-disable-next-line import/prefer-default-export
export const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  bottom: -10px;
  margin-top: ${props => (props.position === 'top' ? '-20px' : '0')};
  transform: rotate(${props => (props.position === 'top' ? '0deg' : '180deg')});
  z-index: 1;
`
