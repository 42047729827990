import React, { useReducer } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from 'rebass'
//
import { Grid, Cell } from '../base-components/grid'
import RichText from '../base-components/rich-text'
import Setting from './Setting'
import {
  StyledSettingsContainer,
  StyledSecondaryTitle,
  StyledScrollable,
  StyledButton,
  StyledRichTextContainer,
  StyledLink
} from './Styled'
import { ContentfulSettings } from '~generated/gatsby.types'

type actionProps = {
  id: string
  checked: boolean
}

const reducer = (state: any, action: actionProps) => ({
  ...state,
  [action.id]: action.checked ? 1 : 0
})

type SettingsProps = {
  setConsentCookie: Function
  showSettings: boolean
  showStatement: boolean
  contentfulSettings: ContentfulSettings
  setShowStatement: Function
  cookieTypes: Record<string, any>
}

const Settings = ({
  setConsentCookie,
  showSettings,
  showStatement,
  contentfulSettings,
  setShowStatement,
  cookieTypes
}: SettingsProps) => {
  const defaults: any = {}
  Object.keys(cookieTypes).forEach((key: string) => {
    defaults[key] = 0
  })

  const [settings, dispatch] = useReducer(reducer, defaults)

  return (
    <>
      <Grid>
        <Cell cols={[12, null, 10]} offset={[0, null, 1]}>
          <StyledSettingsContainer>
            <StyledScrollable>
              {showStatement && (
                <StyledRichTextContainer>
                  <RichText data={contentfulSettings?.cookieBannerStatement?.json} />
                  <Flex justifyContent="center">
                    <StyledLink href="#" onClick={() => setShowStatement(false)}>
                      <FormattedMessage id="cookies.button.hide" />
                    </StyledLink>
                  </Flex>
                </StyledRichTextContainer>
              )}

              {showSettings && (
                <>
                  <StyledSecondaryTitle>
                    <FormattedMessage id="cookies.text.settings.title" />
                  </StyledSecondaryTitle>
                  {Object.keys(cookieTypes).map((key: any) => {
                    if (key === 'necessary') {
                      return <Setting {...cookieTypes[key]} id={key} checked disabled />
                    }
                    return (
                      <Setting
                        {...cookieTypes[key]}
                        id={key}
                        onChange={(checked: boolean) => dispatch({ id: key, checked })}
                      />
                    )
                  })}
                </>
              )}
            </StyledScrollable>
          </StyledSettingsContainer>

          {showSettings && (
            <Flex justifyContent="center">
              <StyledButton theme="white" fullWidth onClick={() => setConsentCookie(settings)}>
                <FormattedMessage id="cookies.button.save" />
              </StyledButton>
            </Flex>
          )}
        </Cell>
      </Grid>
    </>
  )
}

export default Settings
