import styled from 'styled-components'
import { Media } from '~utils'

// eslint-disable-next-line import/prefer-default-export
export const StyledText = styled.div`
  p {
    ${props =>
      Media({
        fontSize: [props.theme.fontSizes.mobile.body, null, props.theme.fontSizes.desktop.body]
      })}
    line-height: ${props => props.theme.lineHeights.body};
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
    line-height: ${props => props.theme.lineHeights.heading};
  }

  h1 {
    ${props =>
      Media({
        fontSize: [props.theme.fontSizes.mobile.h1, null, props.theme.fontSizes.desktop.h1],
        letterSpacing: ['0.67px', null, '1px']
      })}
  }

  h2 {
    ${props =>
      Media({
        fontSize: [props.theme.fontSizes.mobile.h2, null, props.theme.fontSizes.desktop.h2],
        letterSpacing: ['0.8px', null, '1px']
      })}
  }

  h3 {
    ${props =>
      Media({
        fontSize: [props.theme.fontSizes.mobile.h3, null, props.theme.fontSizes.desktop.h3],
        letterSpacing: ['0.8px', null, '1px']
      })}
  }

  h4 {
    ${props =>
      Media({
        fontSize: [props.theme.fontSizes.mobile.h4, null, props.theme.fontSizes.desktop.h4],
        letterSpacing: ['0.8px', null, '1px']
      })}
  }

  a {
    color: ${props => props.theme.colors.darkGrey};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
    padding-bottom: 1px;
  }

  blockquote {
    &, & * {
      font-family: ${props => props.theme.fonts.body};
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;

      text-align: center;
      letter-spacing: 1px;
    }

    max-width: 450px;
    margin: 50px auto;
  }

  a:hover,
  a:active {
    color: ${props => props.theme.colors.mustard};
    border-bottom: 1px solid ${props => props.theme.colors.mustard};
  }

  .image-container {
    position: relative;
    ${Media({
      maxWidth: ['90%', '90%', '90%']
    })}

    img {
      width: 100%;
    }

    ${Media({
      marginTop: ['40px', null, '80px'],
      marginBottom: ['40px', null, '80px']
    })}

    &-centered {
      ${Media({
        maxWidth: ['100%', null, '660px'],
        width: ['calc(100% - 48px)', null, '100%']
      })}
      margin: 0 auto;
      ${Media({
        marginTop: ['40px', null, '80px'],
        marginBottom: ['40px', null, '80px']
      })}

      .gatsby-image-wrapper {
        margin: 0 auto;
        display: block !important;
        
        ${Media({
          maxWidth: ['100%', null, '660px'],
          width: ['calc(100% - 48px)', null, '100%']
        })}
      }
    }
  }

  .podcast-container {
    position: relative;
    max-width: 660px;
    margin: 0 auto;

    ${Media({
      maxWidth: ['100%', null, '660px'],
      width: ['calc(100% - 48px)', null, '100%'],
      marginTop: ['40px', null, '80px'],
      marginBottom: ['40px', null, '80px']
    })}
  }

  ul,
  ol {

    li {
      > p {
        margin: 0;
        line-height: 2;
        letter-spacing: 0.5px;
        ${Media({
          marginBlockStart: ['5px', null, '15px'],
          marginBlockEnd: ['5px', null, '15px']
        })}
      }
    }
  }
`
