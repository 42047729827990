/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Link } from 'gatsby'
//
import { SiteDataContext } from '~layout/wrapper'
import { IntlContext } from '~i18n'

export type NavItemType = {
  id: string
  title: string
  to: string
  accessibleText?: string
}

export type NavLinkType = {
  to: string
  title?: string
  ariaLabel?: string | null | undefined
  children?: React.ReactChild
}

const SITE_URL = process.env.SITE_URL || ''

export const buildSlug = (content: any) => {
  if (!content) {
    return null
  }

  const settings: any = React.useContext(SiteDataContext)
  const { recipesPage, techniquesPage, baristasPage, eventsPage, newsPage, homepage } = settings
  const intl: any = React.useContext(IntlContext)
  const linkParts = ['/']

  if (intl.path) {
    linkParts.push(intl.path)
  }

  switch (content.__typename) {
    case 'recipe':
    case 'ContentfulRecipe':
      linkParts.push(recipesPage.slug)
      break
    case 'technique':
    case 'ContentfulTechnique':
      linkParts.push(techniquesPage.slug)
      break
    case 'barista':
    case 'ContentfulBarista':
      linkParts.push(baristasPage.slug)
      break
    case 'event':
    case 'ContentfulEvent':
      linkParts.push(eventsPage.slug)
      break
    case 'article':
    case 'ContentfulArticle':
      linkParts.push(newsPage?.slug)
      break
    default:
  }

  if (content.id !== homepage.id) {
    linkParts.push(content.slug)
  }

  return linkParts.join('/').replace('//', '/')
}

export const buildNavigationItem = (item: any) => {
  if (!item || (item && !item.externalUrl && !item.content)) {
    return null
  }

  const { id, title, content, externalUrl, accessibleText } = item

  if (externalUrl) {
    return { id, title, to: externalUrl }
  }

  return { id, title, accessibleText, to: buildSlug(content) }
}

export const buildNavigationMenu = (menuId: string) => {
  const settings: any = React.useContext(SiteDataContext)
  const menuEntries = settings[menuId] || []
  const items = menuEntries.map((entry: any) => buildNavigationItem(entry))?.filter((item: any) => item?.id)

  return items
}

export const NavLink = ({ to, title, ariaLabel, children }: NavLinkType) => {
  const props: any = {}

  const path = to.startsWith(SITE_URL) ? to.replace(SITE_URL, '') : to

  if (ariaLabel) {
    props['aria-label'] = ariaLabel
  }

  if (path.startsWith('http')) {
    props.target = '_blank'
    props.rel = 'noreferrer nofollow'

    return (
      <a href={path} {...props}>
        {title || children}
      </a>
    )
  }

  return (
    <Link to={path} {...props}>
      {title || children}
    </Link>
  )
}
