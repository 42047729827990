export const COOKIES_DEFAULTS = {
  statistical: 0,
  personalization: 0,
  targeting: 0
}

const getDecodedCookie = () => {
  if (typeof document !== 'undefined') {
    const cookie = decodeURIComponent(
      /* eslint-disable no-useless-escape */
      document.cookie.replace(/(?:(?:^|.*;\s*)cookieConsent\s*\=\s*([^;]*).*$)|^.*$/, '$1')
      /* eslint-enable no-useless-escape */
    )
    return cookie
  }
  return null
}

export const cookieConsentGiven = () => {
  return !!getDecodedCookie()
}

export const getCookieConsent = (type?: string) => {
  const cookie = getDecodedCookie()
  const consents = (cookie && JSON.parse(cookie)) || COOKIES_DEFAULTS
  if (type && Object.prototype.hasOwnProperty.call(consents, type)) {
    return consents[type]
  }

  return consents
}
