export default {
  colors: {
    text: '#444',
    background: '#fff',
    primary: '#333',
    secondary: '#ede9e6',
    oatmealBrown: '#ede9e6',
    white: '#fff',
    black: '#242424',
    mustard: '#eac060',
    latteBrown: '#ae731f',
    baristaBrown: '#592316',
    lightGrey: '#a4a4a4',
    lightestGrey: '#d3d3d3',
    darkGrey: '#807f7f',
    darkest: '#1d1d1d',
    americanoGrey: '#333'
  },
  fonts: {
    body: 'Geomanist, sans-serif',
    heading: 'Geomanist, sans-serif',
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace'
  },
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 500
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  fontSizes: {
    desktop: {
      body: '16px',
      h1: '60px',
      h2: '40px',
      h3: '28px',
      h4: '22px',
      labels: '12px'
    },
    mobile: {
      body: '16px',
      h1: '40px',
      h2: '32px',
      h3: '24px',
      h4: '20px',
      labels: '12px'
    }
  }
}
