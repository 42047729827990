import React from 'react'
import { FormattedMessage, IntlContext } from 'react-intl'
import { Flex } from 'rebass'
//
import { NavItemType, NavLink } from '~utils/link'
import {
  StyledTopBar,
  StyledTopBarInner,
  StyledTopBarText,
  StyledNavigation,
  StyledNavigationList,
  StyledNavigationListItem
} from './Styled'
import { Grid, Cell } from '../base-components/grid'
import AlproLogo from './AlproLogo'
import Logo from '../base-components/logo'

type Props = {
  navigation: NavItemType[]
}

const DesktopHeader = ({ navigation }: Props) => {
  const firstPathSegment = typeof window !== 'undefined' && window?.location?.pathname?.split('/')[1]
  const intl = React.useContext(IntlContext)

  return (
    <>
      <StyledTopBar>
        <Grid justify="flex-end">
          <Cell cols={[12]}>
            <StyledTopBarInner>
              <StyledTopBarText>
                <FormattedMessage id="header.curatedBy" />
              </StyledTopBarText>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.alpro.com"
                aria-label={intl.formatMessage({ id: 'a11y.alpro' })}
              >
                <AlproLogo />
              </a>
            </StyledTopBarInner>
          </Cell>
        </Grid>
      </StyledTopBar>
      <StyledNavigation>
        <Grid>
          <Cell shrink>
            <Flex alignItems="center">
              <Logo />
              <nav>
                <StyledNavigationList>
                  {navigation.map((item: NavItemType) => (
                    <StyledNavigationListItem
                      key={`header-nav-${item.id}`}
                      isActive={item.to === `/${firstPathSegment}`}
                    >
                      <NavLink
                        to={item.to}
                        ariaLabel={
                          item?.accessibleText || intl.formatMessage({ id: 'a11y.menu' }, { title: item.title })
                        }
                      >
                        <span>{item.title}</span>
                      </NavLink>
                    </StyledNavigationListItem>
                  ))}
                </StyledNavigationList>
              </nav>
            </Flex>
          </Cell>
        </Grid>
      </StyledNavigation>
    </>
  )
}

export default DesktopHeader
