import React from 'react'
//
import locales from './locales'

export { default as locales } from './locales'
export { LocaleType } from './LocaleType'

const defaultLocale = process.env.DEFAULT_LOCALE || locales['en-US']

export const IntlContext = React.createContext(defaultLocale)
export const IntlContextProvider = IntlContext.Provider
export const IntlContextConsumer = IntlContext.Consumer

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flattenMessages = (nestedMessages: any, prefix = '') => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(nestedMessages).reduce((messages: any, key: string) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const getMessages = (key: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-dynamic-require, global-require
    const messages = require(`./messages/${key}.json`)

    return flattenMessages(messages)
  } catch (error) {
    if (error.code === 'MODULE_NOT_FOUND') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-console
      process.env.NODE_ENV !== 'test' && console.error(`[gatsby-plugin-intl] couldn't find file "${key}/${key}.json"`)
    }

    throw error
  }
}
