import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { FormattedMessage } from 'react-intl'
//
import theme from '~theme'
import { Grid, Cell } from '../base-components/grid'
import Logo from '../base-components/logo'
import RoughEdge from '../base-components/rough-edge'
import Settings from './Settings'
import {
  StyledOverlay,
  StyledModal,
  StyledModalInner,
  StyledLogoContainer,
  StyledWelcomeMessageContainer,
  StyledTitle,
  StyledText,
  StyledLink,
  StyledButtonContainer,
  StyledButton
} from './Styled'
import { ContentfulSettings } from '~generated/gatsby.types'

const isServer = typeof window === 'undefined'

type useHtmlOverflowProps = {
  cookieConsent: any
}

const useHtmlOverflow = ({ cookieConsent }: useHtmlOverflowProps) => {
  useEffect(() => {
    if (!cookieConsent) {
      document.documentElement.classList.add('overflow-hidden')
    } else {
      document.documentElement.classList.remove('overflow-hidden')
    }

    return () => {
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [cookieConsent])
}

type CookiesModalProps = {
  contentfulSettings: ContentfulSettings
}

const CookiesModal = ({ contentfulSettings }: CookiesModalProps) => {
  const [cookies, setCookie] = useCookies(['cookieConsent'])
  const [showSettings, setShowSettings] = useState(false)
  const [showStatement, setShowStatement] = useState(false)

  const defaultCookieTypes = [
    {
      key: 'necessary',
      value: '',
      description: ''
    }
  ]

  const cookieTypeDefaults = contentfulSettings?.cookieTypes || defaultCookieTypes

  const cookieTypes: Record<string, any> = {}

  cookieTypeDefaults.forEach((type: any) => {
    cookieTypes[type.key] = type
  })

  const { cookieConsent } = cookies

  useHtmlOverflow({ cookieConsent })

  const setConsentCookie = (data: any) => {
    const expires = new Date()
    expires.setMonth(expires.getMonth() + 1)
    setCookie('cookieConsent', data, { expires })
  }

  const acceptAll = () => {
    setConsentCookie({ statistical: 1, personalization: 1, targeting: 1 })
  }

  return (
    <div id="cookie-modal">
      {!cookieConsent && !isServer && (
        <>
          <StyledOverlay />
          <StyledModal>
            <StyledModalInner>
              <Grid>
                <Cell cols={[12, null, 10]} offset={[0, null, 1]}>
                  <StyledLogoContainer>
                    <Logo />
                  </StyledLogoContainer>
                </Cell>
                <Cell cols={[12, null, 10, 8]} offset={[0, null, 1, 2]}>
                  <StyledWelcomeMessageContainer>
                    <StyledTitle>{contentfulSettings?.cookieBannerTitle}</StyledTitle>
                    <StyledText>{contentfulSettings?.cookieBannerMessage?.cookieBannerMessage}</StyledText>
                    {!showStatement && (
                      <StyledText>
                        <FormattedMessage id="cookies.text.statement" />{' '}
                        <StyledLink href="#" onClick={() => setShowStatement(true)}>
                          <FormattedMessage id="cookies.button.statement" />
                        </StyledLink>
                      </StyledText>
                    )}
                    {!showSettings && (
                      <StyledButtonContainer>
                        <StyledButton theme="white" hollow fullWidth onClick={() => setShowSettings(true)}>
                          <FormattedMessage id="cookies.button.settings" />
                        </StyledButton>
                        <StyledButton theme="white" fullWidth onClick={acceptAll}>
                          <FormattedMessage id="cookies.button.accept" />
                        </StyledButton>
                      </StyledButtonContainer>
                    )}
                  </StyledWelcomeMessageContainer>
                </Cell>
              </Grid>
              {(showSettings || showStatement) && (
                <Settings
                  setConsentCookie={setConsentCookie}
                  showSettings={showSettings}
                  showStatement={showStatement}
                  contentfulSettings={contentfulSettings}
                  setShowStatement={setShowStatement}
                  cookieTypes={cookieTypes}
                />
              )}
            </StyledModalInner>
            <RoughEdge id="cookies" backgroundColor={theme.colors.black} position="bottom" />
          </StyledModal>
        </>
      )}
    </div>
  )
}

export default CookiesModal
