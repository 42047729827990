import React, { useState, useEffect } from 'react'
import { FormattedMessage, IntlContext } from 'react-intl'
//
import { Flex } from 'rebass'
import { NavItemType, NavLink } from '~utils/link'
import {
  StyledMobileHeaderContainer,
  StyledButton,
  StyledMobileNavigationContainer,
  StyledMobileNavigationContainerTop,
  StyledMobileNavigationContainerBottom,
  StyledMobileNavigationContainerBottomText,
  StyledMobileNavigationListContainer,
  StyledMobileNavigationList,
  StyledMobileNavigationListItem,
  StyledCloseButton
} from './Styled'
import { Grid, Cell } from '../base-components/grid'
import Logo from '../base-components/logo'
import AlproLogo from './AlproLogo'
import Hamburger from './Hamburger'
import { useCurrentBreakpoint } from '~utils/hooks'
import { mediumBreakpoint } from '~utils/breakpoints'

type Props = {
  navigation: NavItemType[]
}

const Header = ({ navigation }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentBreakpoint = useCurrentBreakpoint()
  const intl = React.useContext(IntlContext)

  useEffect(() => {
    if (isOpen && currentBreakpoint <= mediumBreakpoint) {
      document.documentElement.setAttribute('style', 'overflow:hidden;')
    } else {
      document.documentElement.removeAttribute('style')
    }

    return () => {
      document.documentElement.removeAttribute('style')
    }
  }, [isOpen, currentBreakpoint])

  return (
    <>
      <StyledMobileHeaderContainer>
        <Grid justify="space-between">
          <Cell shrink>
            <Logo isSmall />
          </Cell>
          <Cell shrink>
            <Flex alignItems="center" height="100%">
              <StyledButton
                onClick={() => setIsOpen(true)}
                aria-label={intl.formatMessage({ id: 'a11y.hamburger.open' })}
              >
                <Hamburger />
              </StyledButton>
            </Flex>
          </Cell>
        </Grid>
      </StyledMobileHeaderContainer>
      <StyledMobileNavigationContainer isOpen={isOpen}>
        <Grid justify="center">
          <Cell>
            <StyledMobileNavigationContainerTop>
              <Logo />
              <StyledCloseButton
                onClick={() => setIsOpen(false)}
                aria-label={intl.formatMessage({ id: 'a11y.hamburger.close' })}
              />
            </StyledMobileNavigationContainerTop>
          </Cell>
        </Grid>
        <StyledMobileNavigationListContainer>
          <Grid justify="center">
            <Cell>
              <nav>
                <StyledMobileNavigationList>
                  {navigation.map((item: NavItemType) => (
                    <StyledMobileNavigationListItem key={`header-nav-${item.id}`}>
                      <NavLink
                        to={item.to}
                        ariaLabel={
                          item?.accessibleText || intl.formatMessage({ id: 'a11y.menu' }, { title: item.title })
                        }
                      >
                        {item.title}
                      </NavLink>
                    </StyledMobileNavigationListItem>
                  ))}
                </StyledMobileNavigationList>
              </nav>
            </Cell>
          </Grid>
        </StyledMobileNavigationListContainer>
        <Grid justify="center">
          <Cell>
            <StyledMobileNavigationContainerBottom>
              <StyledMobileNavigationContainerBottomText>
                <FormattedMessage id="header.curatedBy" />
              </StyledMobileNavigationContainerBottomText>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.alpro.com"
                aria-label={intl.formatMessage({ id: 'a11y.alpro' })}
              >
                <AlproLogo fill="#fff" />
              </a>
            </StyledMobileNavigationContainerBottom>
          </Cell>
        </Grid>
      </StyledMobileNavigationContainer>
    </>
  )
}

export default Header
