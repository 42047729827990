import styled from 'styled-components'
//
import { Media } from '~utils'
import Button from '../base-components/button'

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: #000;
  z-index: 4;
`

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.secondary};
  z-index: 5;
`

export const StyledModalInner = styled.div`
  overflow: auto;
  max-height: 100vh;

  ${Media({
    padding: ['40px 0 100px', null, '40px 0']
  })}
`

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.oatmealBrown};
`

export const StyledWelcomeMessageContainer = styled.div`
  padding-top: 40px;
`

export const StyledTitle = styled.h1`
  font-size: 40px;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;

  ${Media({
    letterSpacing: ['0.67px', null, null, '1px'],
    margin: ['0 0 24px', null, '0 0 34px', '0 0 16px']
  })}
`

export const StyledText = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 0 40px;
`

export const StyledLink = styled.a`
  color: ${props => props.theme.colors.mustard} !important;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.colors.mustard};
  padding-bottom: 10px;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media({
    flexDirection: ['column', null, null, 'row']
  })}
`

export const StyledButton = styled(Button)`
  ${Media({
    width: [null, null, '328px'],
    marginRight: [null, null, null, '24px'],
    marginBottom: ['16px', null, null, '0']
  })}

  &:last-child {
    margin: 0;
  }
`

export const StyledSecondaryTitle = styled.h2`
  font-weight: normal;
  text-align: center;
  margin: 0 0 24px;

  ${Media({
    fontSize: ['32px', null, null, '28px'],
    letterSpacing: ['0.8px', null, null, '1px']
  })}
`

export const StyledSettingsContainer = styled.div`
  padding: 40px 0;
  margin: 40px 0;
  border-top: 1px solid ${props => props.theme.colors.oatmealBrown};
  border-bottom: 1px solid ${props => props.theme.colors.oatmealBrown};
`

export const StyledScrollable = styled.div`
  overflow: auto;
  max-height: 375px;

  ${Media({
    padding: ['0 22px 0 0', null, '0 40px 0 0', '0 20%']
  })}

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.oatmealBrown};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.mustard};
    border-radius: 4px;
  }
`

export const StyledCookieSetting = styled.div`
  padding: 0 0 24px;
`

export const StyledLabel = styled.label`
  display: flex;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.5px;
`

export const StyledLabelText = styled.span`
  flex: 1;
`

export const StyledInputContainer = styled.span`
  position: relative;
  margin-top: 9px;
  height: 19px;
  width: 19px;
  margin-right: 16px;
`

export const StyledFakeInput = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.darkGrey};

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid ${props => props.theme.colors.black};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${StyledFakeInput} {
    background-color: ${props => props.theme.colors.mustard};
    border-color: ${props => props.theme.colors.mustard};

    &:after {
      display: block;
    }
  }

  &:disabled ~ ${StyledFakeInput} {
    background-color: ${props => props.theme.colors.darkGrey};
    border-color: ${props => props.theme.colors.darkGrey};
  }
`

export const StyledRichTextContainer = styled.div`
  margin-bottom: 40px;

  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }

  p {
    line-height: 2;
  }

  a {
    color: ${props => props.theme.colors.oatmealBrown};
  }
`
