import styled from 'styled-components'
//
import { Media } from '~utils'

export const StyledTopBar = styled.div`
  background-color: ${props => props.theme.colors.pale};
  ${Media({
    display: ['none', null, 'block']
  })}
`

export const StyledTopBarInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
`

export const StyledTopBarText = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0 9px 0 0;
  font-weight: 500;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.67px;
`

export const StyledNavigation = styled.div`
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 3;
  transition: background-color 100ms;
  background-color: ${props => props.theme.colors.darkest};

  ${Media({
    display: ['none', null, null, 'block']
  })}
`

export const StyledNavigationList = styled.ul`
  margin: 0 0 0 54px;
  padding: 0;
  list-style: none;
  display: flex;
`

interface StyledNavigationListItemProps {
  isActive?: boolean
}

export const StyledNavigationListItem = styled.li<StyledNavigationListItemProps>`
  margin: 0 0 0 9px;

  a {
    width: auto;
    height: 40px;
    padding-left: 48px;
    padding-right: 48px;
    text-transform: uppercase;
    border-radius: 10px;
    letter-spacing: 2px;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    border: 1px solid ${props => props.theme.colors.white};
    color: ${props => (props.isActive ? props.theme.colors.black : props.theme.colors.white)};
    background-color: ${props => (props.isActive ? props.theme.colors.oatmealBrown : 'transparent')};

    &:hover {
      background-color: ${props => props.theme.colors.oatmealBrown};
      color: ${props => props.theme.colors.black};
    }
  }
`

export const StyledMobileHeaderContainer = styled.div`
  background-color: ${props => props.theme.colors.darkest};
  height: 64px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;

  ${Media({
    display: ['flex', null, null, 'none']
  })}
`

export const StyledButton = styled.button`
  background: none;
  border: none;
`

interface StyledMobileNavigationContainerProps {
  isOpen?: boolean
}

export const StyledMobileNavigationContainer = styled.div<StyledMobileNavigationContainerProps>`
  background-color: ${props => props.theme.colors.darkest};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  transform: translateX(${props => (props.isOpen ? '0' : '100%')});
  transition: transform 250ms;

  ${Media({
    display: ['flex', null, null, 'none']
  })}
`

export const StyledMobileNavigationContainerTop = styled.div`
  padding: 24px 0;
  padding-top: 56px;
  width: 100%;
  position: relative;
`

export const StyledMobileNavigationListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledMobileNavigationContainerBottom = styled.div`
  padding: 24px 0;
  border-top: 1px solid ${props => props.theme.colors.oatmealBrown};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledMobileNavigationContainerBottomText = styled.p`
  color: ${props => props.theme.colors.white};
  margin: 0 9px 0 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.67px;
`

export const StyledMobileNavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
`

export const StyledMobileNavigationListItem = styled.li`
  text-align: center;
  font-size: 30px;
  margin-bottom: 40px;

  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
`

export const StyledCloseButton = styled.button`
  width: 14px;
  height: 14px;
  background: none;
  border: none;
  position: relative;
  top: 24px;
  right: 0;
  position: absolute;

  &:before,
  &:after {
    position: absolute;
    left: 6px;
    top: 0;
    content: ' ';
    height: 14px;
    width: 2px;
    background-color: ${props => props.theme.colors.white};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
