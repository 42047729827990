import * as React from 'react'
//
import { buildNavigationMenu } from '~utils/link'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

const Header = () => {
  const navigation = buildNavigationMenu('headerLeftNavigation')

  return (
    <>
      <DesktopHeader navigation={navigation} />
      <MobileHeader navigation={navigation} />
    </>
  )
}

export default Header
